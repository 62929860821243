<template>
  <v-app id="viewpoint">
    <v-main>
      <v-row class="login-wrapper">
        <v-col
          cols="12"
          sm="4"
          md="5"
          lg="5"
          xl="5"
          class="d-none d-sm-block d-md-block d-lg-block d-xl-block"
          v-bind:style="leftPanelStyle"
        ></v-col>
        <v-col>
          <v-row class="login-wrapper" align="center" justify="center">
            <v-col cols="8">
              <img alt :src="mainLogo" width="200" class="mb-9" />
              <h1>Create New Password</h1>
              <p class="mb-7">
                Please confirm the email address that your wish to reset the
                password for. Input the new password of your choice and repeat
                it in the confirmation box
              </p>
              <v-alert prominent type="success" v-if="success">
                <v-row align="center">
                  <v-col class="grow"
                    >We can confirm that your password has been successfully
                    reset!</v-col
                  >
                  <v-col class="shrink">
                    <v-btn :to="{ name: 'login' }">Go To Login</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
              <v-form
                @submit.prevent="resetPassword"
                method="post"
                class="mb-3"
                v-if="!success"
              >
                <v-alert type="error" v-if="hasError"
                  >Sorry, something went wrong when attempting to reset your
                  password. Please check the passwords match and try
                  again</v-alert
                >
                <v-text-field
                  label="Email"
                  name="email"
                  prepend-icon="mdi-account"
                  type="email"
                  v-model="email"
                ></v-text-field>
                <v-text-field
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  v-model="password"
                ></v-text-field>
                <v-text-field
                  label="Confirm Password"
                  name="passwordConfirmation"
                  prepend-icon="mdi-lock"
                  type="password"
                  v-model="passwordConfirmation"
                ></v-text-field>
                <v-btn
                  block
                  large
                  color="primary"
                  type="submit"
                  :loading="this.$store.getters.showLoader"
                  >Reset Password</v-btn
                >
              </v-form>
              <p class="caption">
                <router-link :to="{ name: 'login' }"
                  >Return to login</router-link
                >
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      email: null,
      password: null,
      passwordConfirmation: null,
      hasError: false,
      success: false,
    };
  },

  computed: {
    leftPanelStyle() {
      return {
        backgroundImage: `url('${window.location.origin}/img/${this.$store.getters.brand.background}')`,
        backgroundColor: "#212121",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      };
    },
    mainLogo() {
      return `${window.location.origin}/img/${this.$store.getters.brand.logo}`;
    },
  },

  methods: {
    resetPassword() {
      const email = this.email;
      const password = this.password;
      const password_confirmation = this.passwordConfirmation;
      const token = this.$route.params.token;

      this.$store
        .dispatch("saveResetPassword", {
          email,
          password,
          password_confirmation,
          token,
        })
        .then(() => {
          this.email = null;
          this.password = null;
          this.passwordConfirmation = null;
          this.hasError = false;
          this.success = true;
        })
        .catch(() => {
          this.hasError = true;
        });
    },
  },
};
</script>

<style scoped>
.login-wrapper {
  height: 100%;
}
.login-left {
  background-color: #212121;
}
</style>
